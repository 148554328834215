export const AccountDetails = {
  data: {
    activeLoans: [],
    applicant: {},
    applicants: [],
    application: {},
    customer: {},
    loanHistory: [],
    skip_session: '',
  },
  status: '',
  statusText: '',
}
