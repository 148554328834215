// stores/filesStore.js
import { atom } from 'jotai'

/**
 * @typedef {Object} DocumentViewerState
 * @property {boolean} isOpen - Whether the document viewer is open
 * @property {Array<any>} files - Array of file objects
 * @property {boolean} isLoading - Loading state indicator
 * @property {string|null} error - Error message if any
 * @property {string} documentUrl - URL of the current document
 * @property {Object} params - Document viewer parameters
 * @property {string} params.applicantId - ID of the applicant
 * @property {string} params.documentName - Name of the document
 */

/**
 * @typedef {Object} DocumentViewerData
 * @property {Object} applicant - Applicant information
 * @property {Object} applicant.sorad - SORAD data
 * @property {Array<any>} applicant.sorad.files - Array of file objects
 */

/**
 * Initial state for document viewer and files
 */
export const INITIAL_STATE = {
  isOpen: false,
  files: [],
  isLoading: false,
  error: null,
  documentUrl: '',
  params: {
    applicantId: '',
    documentName: 'Credit Score Disclosure',
  },
}

/**
 * Base atoms for storing fundamental document viewer state
 * @type {import('jotai').Atom<any>}
 */
const baseIsDocumentViewerOpenAtom = atom(INITIAL_STATE.isOpen)
const baseDocumentViewerParamsAtom = atom(INITIAL_STATE.params)
const baseDocumentUrlAtom = atom(INITIAL_STATE.documentUrl)
const baseFilesAtom = atom(INITIAL_STATE.files)
const baseIsLoadingAtom = atom(INITIAL_STATE.isLoading)
const baseErrorAtom = atom(INITIAL_STATE.error)

/**
 * Primary atoms with read/write capabilities
 */

/**
 * Atom for managing document viewer open state
 * @type {import('jotai').WritableAtom<boolean, [boolean], void>}
 */
export const isDocumentViewerOpenAtom = atom(
  (get) => get(baseIsDocumentViewerOpenAtom),
  (get, set, newValue) => set(baseIsDocumentViewerOpenAtom, newValue)
)

/**
 * Atom for managing document viewer parameters
 * @type {import('jotai').WritableAtom<Object, [Object], void>}
 */
export const documentViewerParamsAtom = atom(
  (get) => get(baseDocumentViewerParamsAtom),
  (get, set, newValue) => set(baseDocumentViewerParamsAtom, newValue)
)

/**
 * Atom for managing document URL
 * @type {import('jotai').WritableAtom<string, [string], void>}
 */
export const documentUrlAtom = atom(
  (get) => get(baseDocumentUrlAtom),
  (get, set, newValue) => set(baseDocumentUrlAtom, newValue)
)

/**
 * Atom for managing files array
 * @type {import('jotai').WritableAtom<Array<any>, [Array<any>], void>}
 */
export const filesAtom = atom(
  (get) => get(baseFilesAtom),
  (get, set, newValue) => set(baseFilesAtom, newValue)
)

/**
 * Atom for managing loading state
 * @type {import('jotai').WritableAtom<boolean, [boolean], void>}
 */
export const isLoadingAtom = atom(
  (get) => get(baseIsLoadingAtom),
  (get, set, newValue) => set(baseIsLoadingAtom, newValue)
)

/**
 * Atom for managing error state
 * @type {import('jotai').WritableAtom<string|null, [string|null], void>}
 */
export const errorAtom = atom(
  (get) => get(baseErrorAtom),
  (get, set, newValue) => set(baseErrorAtom, newValue)
)

/**
 * Atom for getting the total number of files
 * @type {import('jotai').Atom<number>}
 */
export const filesCountAtom = atom((get) => get(filesAtom).length)

/**
 * Atom for checking if there are any files
 * @type {import('jotai').Atom<boolean>}
 */
export const hasFilesAtom = atom((get) => get(filesAtom).length > 0)

/**
 * Action atoms for state modifications
 */

/**
 * Atom for setting files with error clearing
 * @type {import('jotai').WritableAtom<Array<any>, [Array<any>], void>}
 */
export const setFilesAtom = atom(
  (get) => get(filesAtom),
  (get, set, newFiles) => {
    set(filesAtom, newFiles)
    set(errorAtom, null)
  }
)

/**
 * Atom for adding a single file
 * @type {import('jotai').WritableAtom<Array<any>, [string], void>}
 */
export const addFileAtom = atom(
  (get) => get(filesAtom),
  (get, set, fileId) => {
    const currentFiles = get(filesAtom)
    if (!currentFiles.includes(fileId)) {
      set(filesAtom, [...currentFiles, fileId])
    }
  }
)

/**
 * Atom for removing a single file
 * @type {import('jotai').WritableAtom<Array<any>, [string], void>}
 */
export const removeFileAtom = atom(
  (get) => get(filesAtom),
  (get, set, fileId) => {
    const currentFiles = get(filesAtom)
    set(
      filesAtom,
      currentFiles.filter((id) => id !== fileId)
    )
  }
)

/**
 * Atom for clearing all files
 * @type {import('jotai').WritableAtom<Array<any>, [], void>}
 */
export const clearFilesAtom = atom(
  (get) => get(filesAtom),
  (get, set) => {
    set(filesAtom, INITIAL_STATE.files)
    set(errorAtom, null)
  }
)

/**
 * Atom for loading files from API data
 * @type {import('jotai').WritableAtom<Array<any>, [DocumentViewerData], void>}
 */
export const loadFilesAtom = atom(
  (get) => get(filesAtom),
  async (get, set, data) => {
    try {
      set(isLoadingAtom, true)
      set(errorAtom, null)

      const files = data?.applicant?.sorad?.files
      if (Array.isArray(files)) {
        set(filesAtom, files)
      } else {
        throw new Error('Invalid files data')
      }
    } catch (error) {
      set(errorAtom, error.message)
    } finally {
      set(isLoadingAtom, false)
    }
  }
)

/**
 * Initializes document viewer state
 * @param {Object} setter - Object containing setter functions for each state
 * @returns {void}
 */
export const initializeDocumentViewerState = (setter) => {
  setter.setIsDocumentViewerOpen(INITIAL_STATE.isOpen)
  setter.setDocumentViewerParams(INITIAL_STATE.params)
  setter.setDocumentUrl(INITIAL_STATE.documentUrl)
  setter.setFiles(INITIAL_STATE.files)
  setter.setIsLoading(INITIAL_STATE.isLoading)
  setter.setError(INITIAL_STATE.error)
}
