import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import BookIcon from '@mui/icons-material/Book'
import CallIcon from '@mui/icons-material/Call'
import DataUsageOutlinedIcon from '@mui/icons-material/DataUsageOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import ListIcon from '@mui/icons-material/List'
import LiveHelpIcon from '@mui/icons-material/LiveHelp'
import MenuIcon from '@mui/icons-material/Menu'
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import SettingsIcon from '@mui/icons-material/Settings'
import { Box, Checkbox } from '@mui/material'
import { Button } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Backdrop from '@mui/material/Backdrop'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import Grow from '@mui/material/Grow'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import Cookies from 'js-cookie'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useRef, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { useQuery, useQueryClient } from 'react-query'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import applicationStatusRedirectPage from '../../../assets/data/applicationStatusRedirectPage.json'
import globalMessages from '../../../assets/data/globalMessages.json'
import DeleteAccountIcon from '../../../assets/icon/CustomIcons/DeleteAccountIcon'
import logoIcon from '../../../assets/images/Favicon.png'
import logoImage from '../../../assets/images/Normallogo.png'
import profileImg from '../../../assets/images/profile-img.jpg'
import quickPay from '../../../assets/images/quickpay.png'
// import { CheckMyOffers } from '../../../contexts/CheckMyOffers'
import { useAtom, useSetAtom } from 'jotai'
import { useGlobalState } from '../../../contexts/GlobalStateProvider'
import { LoanAccount } from '../../../contexts/LoanAccount'
import { NavContext } from '../../../contexts/NavContext'
import { ProfilePicture } from '../../../contexts/ProfilePicture'
import { CheckOffersAtom, resetData } from '../../../stores/CheckOffersStore'
import usrAccountDetails, { postFullStorySession } from '../../Controllers/AccountOverviewController'
import { verificationSteps } from '../../Controllers/ApplyForLoanController'
import LogoutController from '../../Controllers/LogoutController'
import MyBranchAPI from '../../Controllers/MyBranchController'
import ProfileImageController from '../../Controllers/ProfileImageController'
import { statusStrLinks } from '../../lib/StatusStrLinks'
import MoneySkill from '../../Pages/MoneySkill/MoneySkill'
import Notification from '../Notification/Notification'
import './SideNav.css'
import { AccountDetailsAtom } from '../../../stores/AccountDetailsStore'
import { useFetchAccountDetails } from '../../../stores/actions/AccountDetailsAction'

// Credit Disclosure Notification
import DocumentViewer from '../../CustomComponent/DocumentViewer/DocumentViewer'
import { isDocumentViewerOpenAtom, documentViewerParamsAtom } from '../../../stores/FilesStore'

/**
 * Shows content only on specified route
 * @param {Object} props
 * @param {string} props.route - Route where content should be shown
 * @param {React.ReactNode} props.children - Content to show
 */
export const ShowOnRoute = ({ route, children }) => {
  const location = useLocation()

  return location.pathname === route ? children : null
}

const drawerWidth = 240

//Material UI css
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },

  menuButton: {
    marginRight: theme.spacing(2),
    color: 'white',
  },
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(9),
    [theme.breakpoints.up('xs')]: {
      width: '0px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '0px',
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(0),
    },
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing(9),
    },
    [theme.breakpoints.up('xl')]: {
      width: theme.spacing(9),
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
  customBadge: {
    backgroundColor: '#ffd524',
    color: 'black',
  },

  logoIcon: {
    maxWidth: 40,
    margin: '13px',
  },
  inputRoot: {
    color: 'inherit',
  },
  headerAlign: {},

  branchLocator: {
    '@media (max-width: 700px)': {
      width: 'min-content',
    },
  },
  headerimg: {
    height: '55px',
    width: '55px',
  },

  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  activeNavbar: {
    background: `linear-gradient(
      85deg
      , #264266, #0F4EB3) !important;
      color:white!important`,
    width: '220px',
    borderRadius: '0 5px 5px 0',
    boxShadow: `3px 3px 10px 0 rgb(123 31 162 / 50%)`,
  },
  logoIconDiv: {
    display: 'none',
  },
  textDecoration: {
    textDecoration: 'none',
  },
}))

export default function SideNav() {
  const classes = useStyles()
  const location = useLocation()
  const [open, setOpen] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)
  const isMenuOpen = Boolean(anchorEl)
  const navigate = useNavigate()
  const [disable, setDisable] = useState(false)
  const [skill, setSkill] = useState(false)
  const [checked, setChecked] = useState(true)
  const [, setProfileTabNumber] = useGlobalState()
  const { dataProfile, resetProfilePicture } = useContext(ProfilePicture)
  // const { resetData } = useContext(CheckMyOffers)
  const [atomData, setAtomData] = useAtom(CheckOffersAtom)
  const { resetLoanAccount } = useContext(LoanAccount)
  const { dataNavmessage } = useContext(NavContext)

  // Replace useQuery with Jotai
  // const { data: dataAccountOverview } = useQuery('loan-data', usrAccountDetails)
  const [dataAccountOverview] = useAtom(AccountDetailsAtom)
  const fetchAccountDetails = useFetchAccountDetails()

  const queryClient = useQueryClient()
  const [activeLoanData, setActiveLoanData] = useState(true)
  const [currentLoan, setCurrentLoan] = useState(true)
  const [checkPresenceOfLoan, setCheckPresenceOfLoan] = useState(false)
  const [checkPresenceOfLoanStatus, setCheckPresenceOfLoanStatus] = useState('')
  const [isMobileDevice, setDeviceType] = useState(false)
  const [checkFinalVerificationStatus, setCheckFinalVerificationStatus] = useState(false)
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const { data: verificationStepsApplyforLoan } = useQuery('verification-data', verificationSteps, {
    enabled: ['approved', 'completing_application', 'signature_complete', 'closing_process'].includes(
      dataAccountOverview?.data?.applicants?.find((applicant) => applicant?.isActive)?.status
    ),
  })
  const [option, setOpenOption] = React.useState(false)
  const anchorRef = React.useRef(null)
  let refProfileDetails = useRef()
  let refApplyForLoanNav = useRef()
  let refClose2 = useRef()
  let refClose = useRef()
  let loanStatus = ['under_review', 'final_review']

  // Handle Document Viewer for credit Score Disclosure Start
  const setIsDocumentViewerOpen = useSetAtom(isDocumentViewerOpenAtom)
  const setDocumentViewerParams = useSetAtom(documentViewerParamsAtom)

  const handleOpenCreditScoreDisclosure = (applicantId) => {
    setDocumentViewerParams({
      applicantId: applicantId,
      documentName: 'Credit Score Disclosure',
    })
    setIsDocumentViewerOpen(true)
  }

  // End of Document Viewer for credit Score Disclosure

  const handleClickAway = () => {
    if (isMobileDevice) {
      setOpen(false)
      setOpenBackdrop(false)
    }
  }
  const handleDeviceType = (status) => {
    setDeviceType(status)
  }
  window.fullStorySession = function (customer) {
    if (customer?.identification?.guid) {
      Cookies.set(
        'fsIdentifyDetails',
        JSON.stringify({ GUID: customer?.identification?.guid, displayName: customer?.identification?.full_name })
      )
      if (
        window.FS &&
        window.FS.getCurrentSessionURL &&
        typeof window.FS.getCurrentSessionURL === 'function' &&
        window.FS.getCurrentSessionURL() !== null
      ) {
        let fsSession = window.FS.getCurrentSessionURL()
        let fsSessionKey = fsSession.split('/')[fsSession.split('/').length - 1]
        postFullStorySession(customer, fsSession, fsSessionKey)
      }
    }
  }

  useEffect(() => {
    let noOfLoans = dataAccountOverview?.data?.activeLoans?.length
    let activeLoan = dataAccountOverview?.data?.applicants
    //logic to check if atleast one active initiated Loan is there or not
    const presenceOfLoan = activeLoan?.some(
      (applicant) => applicant.isActive && applicant?.status !== 'referred' && applicant?.status !== 'contact_branch'
    )
    const presenceOfLoanStatus = activeLoan?.find((applicant) => applicant?.isActive)
    const userAccountStatus = dataAccountOverview?.data?.customer?.user_account?.status

    setCheckPresenceOfLoanStatus(presenceOfLoanStatus?.status)
    Cookies.set('isActiveApplicationExist', presenceOfLoanStatus?.isActive ?? false)
    Cookies.set('activeApplicationGuid', presenceOfLoanStatus?.applicantGuid ?? '')

    if (activeLoan) {
      // check if activeLoan is not empty and has an object with a value applicatGuid if it does exist update the pplicant id joti state for the document viewer
      let applicantId = activeLoan[0]?.applicantGuid ?? ''
      if (applicantId) {
        setDocumentViewerParams({
          applicantId: applicantId,
          documentName: 'Credit Score Disclosure',
        })
      }
    }

    setCurrentLoan(presenceOfLoan || userAccountStatus === 'closed' ? true : false)
    setCheckPresenceOfLoan(presenceOfLoan)
    //logic to if there is any active Loan Data is there or not
    if (!noOfLoans) {
      setActiveLoanData(true)
    } else {
      setActiveLoanData(false)
    }
    return () => {
      setCurrentLoan({})
    }
  }, [dataAccountOverview, activeLoanData, currentLoan])

  useEffect(() => {
    const customer = dataAccountOverview?.data?.customer
    fullStorySession(customer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAccountOverview?.data?.customer])

  const getFinalApplicationStatus = async () => {
    if (
      verificationStepsApplyforLoan?.data?.email &&
      verificationStepsApplyforLoan?.data?.financial_information &&
      verificationStepsApplyforLoan?.data?.id_document &&
      verificationStepsApplyforLoan?.data?.id_photo &&
      verificationStepsApplyforLoan?.data?.id_questions &&
      verificationStepsApplyforLoan?.data?.bank_account_information &&
      verificationStepsApplyforLoan?.data?.bank_account_verification &&
      verificationStepsApplyforLoan?.data?.income_verification
    ) {
      setCheckFinalVerificationStatus(true)
    }
  }

  useEffect(() => {
    getFinalApplicationStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationStepsApplyforLoan])

  //Material UI media query for responsiveness
  let check = useMediaQuery('(min-width:960px)')

  useEffect(() => {
    if (check && checked) {
      setChecked(true)
      setOpen(true)
      handleDeviceType(false)
      document.getElementById('main').style.marginLeft = '240px'
      document.getElementById('close').style.display = 'none'
    } else {
      setOpen(false)
      handleDeviceType(true)
    }
  }, [checked, check])

  //Formating Phone Number
  function formatPhoneNumber(phoneNumber) {
    if (phoneNumber) {
      const cleanNum = phoneNumber.toString().replace(/\D/g, '')
      const match = cleanNum.match(/^(\d{3})(\d{0,3})(\d{0,4})$/)
      if (match) {
        return '(' + match[1] + ') ' + (match[2] ? match[2] + '-' : '') + match[3]
      }
      return cleanNum
    } else {
      return false
    }
  }

  //Api call Branch Details
  const { data: branchVal } = useQuery('my-branch', MyBranchAPI)
  const [branchAvailability, setBranchAvailability] = useState(false)

  useEffect(() => {
    if (branchVal) {
      setBranchAvailability(branchVal?.data?.branchIsOpen)
    }
  }, [branchVal])

  //Api call Profile Picture
  const { data: profileImage } = useQuery('my-profile-picture', ProfileImageController)
  let getProfImage = profileImage ?? profileImg

  // Side bar branch details
  Cookies.set(
    'branchname',
    branchVal?.data?.BranchName
      ? branchVal?.data?.BranchName
      : branchVal?.data?.branchName
      ? branchVal?.data?.branchName
      : ''
  )
  Cookies.set('branchphone', branchVal?.data?.PhoneNumber)
  Cookies.set('getProfileImage', getProfImage)

  let hasActiveLoan = /true/i.test(Cookies.get('hasActiveLoan'))
  let hasApplicationStatus = Cookies.get('hasApplicationStatus')
  let appStatus = ['rejected', 'referred', 'expired']
  let checkAppStatus = appStatus.includes(hasApplicationStatus)
  let disableField = checkAppStatus && !hasActiveLoan ? true : !checkAppStatus && !hasActiveLoan ? true : false
  const branchName = Cookies.get('branchname')
  const branchPhone = Cookies.get('branchphone')
  const getProfileImage = Cookies.get('getProfileImage')

  const lastLoginRaw = Cookies.get('userLastLogin')
  const loginDate = lastLoginRaw ? new Date(lastLoginRaw) : new Date()
  const lastLogin =
    (loginDate.getMonth() > 8 ? loginDate.getMonth() + 1 : String(loginDate.getMonth() + 1).padStart(2, '0')) +
    '/' +
    (loginDate.getDate() > 9 ? loginDate.getDate() : String(loginDate.getDate()).padStart(2, '0')) +
    '/' +
    loginDate.getFullYear()

  //Side bar open on mouse event
  const handleDrawer = () => {
    const closeElementId = 'close'
    const valueQualifiedName = 'value'

    if (!checked || !check) {
      let profiledetailTag = refProfileDetails.current
      profiledetailTag.style.display = 'block'
      let menuValue = refClose.current.getAttribute(valueQualifiedName)

      if (menuValue === closeElementId) {
        setOpen(true)
        refClose.current.setAttribute(valueQualifiedName, 'open')
      } else {
        setOpen(false)
        refClose.current.setAttribute(valueQualifiedName, closeElementId)
      }
      const child = refClose.current
      const parent = refClose2.current
      if (parent.offsetWidth > 73) {
        child.style.display = 'block'
      } else {
        child.style.display = 'none'
      }
      if (!checked || !check) {
        document.getElementById('main').style.marginLeft = '73px'
      } else {
        document.getElementById('main').style.marginLeft = '240px'
      }
    }
  }

  //Side bar close on mouse event
  const handleDrawerleave = () => {
    const closeElementId = 'close'
    const valueQualifiedName = 'value'

    if (!checked || !check) {
      let profiledetailTag = refProfileDetails.current
      profiledetailTag.style.display = 'none'
      let menuValue = refClose.current.getAttribute(valueQualifiedName)

      if (menuValue === closeElementId) {
        setOpen(true)
        refClose.current.setAttribute(valueQualifiedName, 'close')
      } else {
        setOpen(false)
        refClose.current.setAttribute(valueQualifiedName, closeElementId)
      }
      const child = refClose.current
      const parent = refClose2.current

      if (parent.offsetWidth > 73) {
        child.style.display = 'block'
      } else {
        child.style.display = 'none'
      }
      if (!checked || !check) {
        document.getElementById('main').style.marginLeft = '73px'
      } else {
        document.getElementById('main').style.marginLeft = '240px'
      }
    }
  }
  const isActiveLink = (targetPath) => {
    return location.pathname === targetPath
  }

  const resumeNavigate = (appData) => {
    let stateDataToPass = {
      partnerSignupData: {
        applicant: {
          contact: {
            last_name: Cookies.get('lastName'),
            first_name: Cookies.get('firstName'),
          },
        },
      },
      referrer: 'partnerPage',
    }
    navigate(statusStrLinks[appData], { state: stateDataToPass })
  }

  const resumeNavigateSelectOffer = () => {
    setData({ ...dataNavmessage, status: true })
    navigate('/customers/selectOffer')
  }
  //Menu button on mouse view
  const handleMenuButtonOpen = () => {
    setOpenBackdrop(true)
    if (!check) {
      let drawerRefClose2 = refClose2.current
      drawerRefClose2.style.display = 'block '
      setOpen(true)
      let profiledetailTag = refProfileDetails.current
      profiledetailTag.style.display = 'block'
    }
  }

  //Money skill popup
  const handleMoneySkillNav = () => {
    setSkill(true)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMobileMenuClose = () => {
    if ((!checked || !check) && window.matchMedia('only screen and (max-width: 920px)').matches) {
      setOpen(false)
      setOpenBackdrop(false)
    }
  }

  const handleMenuProfile = (navType) => {
    if (navType === 'top') {
      navigate('/customers/myProfile')
    }
    setProfileTabNumber({ profileTabNumber: 0 })
    handleMenuClose()
  }
  const handleMenuPaymentProfile = (event) => {
    navigate('/customers/myProfile')
    setProfileTabNumber({ profileTabNumber: 3 })
    handleClose(event)
    handleMenuClose()
  }

  function logOut() {
    setAnchorEl(null)
    queryClient.removeQueries()
    LogoutController()
    const accountDetail = usrAccountDetails()
    if (accountDetail?.status === 200) {
      UserAccountDetails(accountDetail)
    } else {
      resetData(atomData, setAtomData)
    }
    resetLoanAccount()
    resetProfilePicture()
    navigate('/login')
  }

  const logoutUser = () => {
    setDisable(true)
    toast.success(globalMessages.LoggedOut, {
      onClose: () => logOut(),
    })
  }

  //Side bar enable and disable
  const handleChangeCheckbox = (event) => {
    if (event.target.checked === 'false') {
      document.getElementById('main').style.marginLeft = '73px'
    } else {
      document.getElementById('main').style.marginLeft = '240px'
    }
    setChecked(event.target.checked)
  }

  //Menu bar
  const renderMenu = (
    <Menu id="settingsMenu" anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
      <MenuItem onClick={(_menuType) => handleMenuProfile('top')} id="settingsMenuList">
        My Profile
      </MenuItem>
      <MenuItem disabled={disableField} onClick={handleMenuPaymentProfile} id="settingsMenuList">
        Payment Methods
      </MenuItem>
      <MenuItem onClick={logoutUser} id="settingsMenuListLogout" disabled={disable}>
        Logout
      </MenuItem>
    </Menu>
  )
  let navElement = refApplyForLoanNav.current
  if (navElement) {
    navElement.removeAttribute('href')
  }

  const handleToggle = () => {
    setOpenOption((prevOpen_toggle) => !prevOpen_toggle)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpenOption(false)
  }

  //for closing the backdrop
  const handleCloseOpenBackdrop = () => {
    setOpenBackdrop(false)
    handleMobileMenuClose()
    handleClickAway()
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(option)
  useEffect(() => {
    if (prevOpen.current === true && option === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = option
  }, [option])

  let stateDataToPass = {
    partnerSignupData: {
      applicant: {
        contact: {
          last_name: Cookies.get('lastName'),
          first_name: Cookies.get('firstName'),
        },
      },
    },
    referrer: 'partnerPage',
  }
  const SideNaveList = ({ label, disabled, SideNavicons }) => {
    return (
      <ListItem disabled={disabled} className="titleSidenav">
        <ListItemIcon> {SideNavicons} </ListItemIcon>
        <ListItemText className={classes.textDecoration}>{label}</ListItemText>
      </ListItem>
    )
  }
  //View part
  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div data-testid="side_nav_component" id="headerWrap" className={classes.grow}>
          <nav id="side-nav-content" aria-label="Side Nav Content">
            <Backdrop
              sx={{ color: '#fff', zIndex: '15' }}
              open={openBackdrop}
              onClick={handleCloseOpenBackdrop}
            ></Backdrop>
            <AppBar
              data-testid="appBar"
              id="MainHeaderWrapping"
              position="static"
              elevation={0}
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Toolbar id="toolbar">
                <IconButton
                  data-testid="menuIcon"
                  aria-label="open drawer"
                  onClick={handleMenuButtonOpen}
                  id="close1"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                  })}
                >
                  <MenuIcon />
                </IconButton>
                <div className={classes.grow} />
                {checkPresenceOfLoan ? (
                  <ShowOnRoute route="/customers/selectOffer">
                    <div id="creditScoreDisclosureNotice">
                      <NavLink data-testid="creditDisclosureNotificationCta" to="#" className="nav_link">
                        <Typography className={classes.headerAlign}>
                          {' '}
                          {globalMessages.Global_Notice_Review}:{' '}
                          <span
                            underline="hover"
                            onClick={() => handleOpenCreditScoreDisclosure()}
                            className="nav_link noticeText"
                            href="#"
                          >
                            {' '}
                            {globalMessages.Credit_Score_Disclosure_Title}
                          </span>
                        </Typography>
                      </NavLink>
                    </div>
                  </ShowOnRoute>
                ) : null}

                <div id="tool-bar-list">
                  <NavLink
                    data-testid="qickPayIcon"
                    id="quickNameIcon"
                    to="/customers/makePayment"
                    onClick={(event) => {
                      activeLoanData && event.preventDefault()
                    }}
                    className={activeLoanData ? 'nav_link_disabled' : ''}
                  >
                    {' '}
                    {!activeLoanData && (
                      <Tooltip title="Quick Pay" placement="bottom">
                        <img
                          className={clsx(classes.headerimg, classes.headerimgResp)}
                          src={quickPay}
                          data-test-id="background"
                          alt="quick pay"
                        />
                      </Tooltip>
                    )}
                  </NavLink>

                  <Notification id="notifications" name="notifications" aria-label="Notifications" />

                  <Stack direction="row" spacing={2}>
                    <div>
                      <IconButton
                        data-testid="settingsIcon"
                        ref={anchorRef}
                        id="composition-button"
                        aria-controls={open ? 'composition-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        aria-label="Settings"
                        onClick={handleToggle}
                      >
                        <SettingsIcon />
                      </IconButton>
                      <Popper
                        open={option}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                  autoFocusItem={open}
                                  id="composition-menu"
                                  aria-labelledby="composition-button"
                                  // onKeyDown={handleListKeyDown}
                                >
                                  <MenuItem
                                    onClick={(event, _menuType) => {
                                      handleMenuProfile('top')
                                      handleClose(event)
                                    }}
                                    id="settingsMenuList"
                                  >
                                    My Profile
                                  </MenuItem>
                                  <MenuItem
                                    disabled={disableField}
                                    onClick={handleMenuPaymentProfile}
                                    id="settingsMenuList"
                                  >
                                    Payment Methods
                                  </MenuItem>
                                  <MenuItem onClick={logoutUser} id="settingsMenuListLogout" disabled={disable}>
                                    Logout
                                  </MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </div>
                  </Stack>
                </div>
              </Toolbar>
            </AppBar>

            {renderMenu}
            <div className={classes.sectionDrawer}>
              <Drawer
                id="close2"
                ref={refClose2}
                variant="permanent"
                onClick={handleMobileMenuClose}
                className={clsx(
                  classes.drawer,
                  {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                  },
                  checked ? 'toggleOpen' : 'toggleClose'
                )}
                classes={{
                  paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                  }),
                }}
                onMouseEnter={handleDrawer}
                onMouseLeave={handleDrawerleave}
              >
                <div className={classes.toolbar}>
                  <a href={`${process.env.REACT_APP_WEBSITE}`} rel="noreferrer">
                    <input type="image" src={logoImage} alt="logo image" id="logoImage" />
                  </a>
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon id="sidemenuRadio" />}
                    checkedIcon={<RadioButtonCheckedIcon id="sidemenuRadio" />}
                    onClick={handleChangeCheckbox}
                    name="sidemenuradio"
                    inputProps={{ 'aria-label': 'Sidemenu Toggle' }}
                    checked={checked}
                  />

                  <div id="close" ref={refClose} className={classes.logoIconDiv}>
                    <img data-testid="mfLogo" src={logoIcon} alt="logo icon" className={classes.logoIcon} />
                  </div>
                </div>
                <Divider />
                <PerfectScrollbar
                  options={{
                    suppressScrollX: true,
                    wheelSpeed: 2,
                    wheelPropagation: false,
                    minScrollbarLength: 20,
                  }}
                >
                  <List id="listItemWrap" onClick={handleMobileMenuClose}>
                    <ListItem id="profileDetails" ref={refProfileDetails}>
                      <List>
                        <ListItem>
                          <div id="imgWrap">
                            <img
                              id="sidebarProfilePic"
                              // src={dataProfile?.profilePictureURL ? dataProfile?.profilePictureURL : getProfileImage}
                              src={profileImg}
                              alt="Profile Pic"
                              onClick={(_navType) => handleMenuProfile('top')}
                            />
                          </div>
                        </ListItem>
                        <ListItem id="sidemenuName">
                          {dataAccountOverview?.data?.applicant?.contact?.first_name
                            ? 'Welcome ' + dataAccountOverview?.data?.applicant?.contact?.first_name
                            : ''}
                        </ListItem>
                        {!branchName || !branchPhone ? (
                          <>
                            <ListItem id="sidemenuLastLogin">{!lastLogin ? '' : `Last Login : ${lastLogin}`}</ListItem>
                          </>
                        ) : (
                          <>
                            <ListItem id="sidemenuLastLogin">{!lastLogin ? '' : `Last Login : ${lastLogin}`}</ListItem>
                            <ListItem id="sidemenuBranch">{!branchName ? '' : `Branch :  ${branchName}`}</ListItem>
                            <ListItem id={branchAvailability ? 'sidemenuOpenNow' : 'sidemenuCloseNow'}>
                              {branchAvailability ? 'Open now' : 'Closed now'}
                            </ListItem>
                            {!formatPhoneNumber(branchPhone) ? (
                              ''
                            ) : (
                              <ListItem id="sidemenuPhone">
                                <CallIcon />
                                <a href="tel:" className="hrefPhoneNo">
                                  {formatPhoneNumber(branchPhone)}
                                </a>
                              </ListItem>
                            )}{' '}
                          </>
                        )}
                      </List>
                    </ListItem>
                    <NavLink
                      data-testid="sideNavAccountOverviewNavigation"
                      to="/customers/accountOverview"
                      className="nav_link"
                    >
                      <SideNaveList SideNavicons={<AssignmentTurnedInOutlinedIcon />} label={'Account Overview'} />
                    </NavLink>
                    <NavLink
                      data-testid="sideNavMakePaymentNavigation"
                      to="/customers/makePayment"
                      onClick={(event) => {
                        activeLoanData && event.preventDefault()
                      }}
                      className={activeLoanData ? 'nav_link_disabled' : 'nav_link'}
                    >
                      <SideNaveList
                        disabled={activeLoanData}
                        SideNavicons={<AccountBalanceWalletIcon />}
                        label={'Make a Payment'}
                      />
                    </NavLink>
                    {checkPresenceOfLoan ? (
                      <NavLink
                        to={applicationStatusRedirectPage[checkPresenceOfLoanStatus]}
                        state={stateDataToPass}
                        className="nav_link"
                      >
                        <SideNaveList SideNavicons={<MonetizationOnRoundedIcon />} label={'Resume Application'} />
                      </NavLink>
                    ) : (
                      <NavLink
                        data-testid="sideNavApplyLoanNavigation"
                        id="applyForLoanNav"
                        ref={refApplyForLoanNav}
                        to="/customers/applyForLoan"
                        state={{ from: 'user' }}
                        onClick={(event) => {
                          currentLoan && event.preventDefault()
                        }}
                        className={currentLoan ? 'nav_link_disabled' : 'nav_link'}
                      >
                        <SideNaveList
                          disabled={currentLoan}
                          SideNavicons={<MonetizationOnRoundedIcon />}
                          label={'Apply for a Loan'}
                        />
                      </NavLink>
                    )}

                    <NavLink
                      data-testid="sideNavLoanDocumentsNavigation"
                      to="/customers/loanDocument"
                      onClick={(event) => {
                        if (activeLoanData && !loanStatus.includes(checkPresenceOfLoanStatus)) {
                          event.preventDefault()
                        }
                      }}
                      className={activeLoanData ? 'nav_link_disabled' : 'nav_link'}
                    >
                      <SideNaveList
                        SideNavicons={<DescriptionOutlinedIcon />}
                        disabled={activeLoanData && !loanStatus.includes(checkPresenceOfLoanStatus)}
                        label={'Loan Documents'}
                      />
                    </NavLink>

                    <NavLink
                      data-testid="sideNavMyBranchNavigation"
                      to="/customers/myBranch"
                      onClick={(event) => {
                        activeLoanData &&
                          (!checkPresenceOfLoanStatus === 'referred' ||
                            !checkPresenceOfLoanStatus === 'contact_branch') &&
                          event.preventDefault()
                      }}
                      className={
                        activeLoanData &&
                        (!checkPresenceOfLoanStatus === 'referred' || !checkPresenceOfLoanStatus === 'contact_branch')
                          ? 'nav_link_disabled'
                          : 'nav_link'
                      }
                    >
                      <SideNaveList SideNavicons={<AccountBalanceIcon />} label={'My Branch'} />
                    </NavLink>

                    <NavLink
                      data-testid="sideNavMyProfileNavigation"
                      to="/customers/myProfile"
                      onClick={(_menuType) => handleMenuProfile('side')}
                      className="nav_link"
                    >
                      <SideNaveList SideNavicons={<AccountCircleIcon />} label={'My Profile'} />
                    </NavLink>

                    <NavLink
                      data-testid="sideNavLoanHistoryNavigation"
                      to="/customers/loanHistory"
                      onClick={(event) => {
                        activeLoanData && event.preventDefault()
                      }}
                      className={activeLoanData ? 'nav_link_disabled' : 'nav_link'}
                    >
                      <SideNaveList disabled={activeLoanData} SideNavicons={<ListIcon />} label={'Loan History'} />
                    </NavLink>

                    <NavLink
                      data-testid="sideNavVantageScoreNavigation"
                      to="/customers/vantageScore"
                      onClick={(event) => {
                        activeLoanData && event.preventDefault()
                      }}
                      className={activeLoanData ? 'nav_link_disabled' : 'nav_link'}
                    >
                      <SideNaveList disabled={activeLoanData} SideNavicons={<InboxIcon />} label={'VantageScore ®'} />
                    </NavLink>

                    <ListItem data-testid="moneySkill" id="moneySkillNavLink" onClick={handleMoneySkillNav}>
                      <ListItemIcon>
                        {' '}
                        <DataUsageOutlinedIcon />{' '}
                      </ListItemIcon>
                      <ListItemText className="titleSidenav"> MoneySKILL &reg; </ListItemText>
                    </ListItem>
                    <a
                      data-testid="sideNavBlogNavigation"
                      href={`${process.env.REACT_APP_WEBSITE}/blog/`}
                      className="titleSidenav"
                      id="blogLinkWrap"
                      rel="noreferrer"
                    >
                      <SideNaveList SideNavicons={<BookIcon />} label={'Blog'} />
                    </a>
                    <a
                      data-testid="sideNavfaqNavigation"
                      id="faqLinkWrap"
                      href={`${process.env.REACT_APP_WEBSITE}/resources/faq/`}
                      rel="noreferrer"
                      className="titleSidenav"
                    >
                      <SideNaveList SideNavicons={<LiveHelpIcon />} label={'FAQ'} />
                    </a>
                    <Box sx={{ marginTop: 2.5, width: '100%' }}>
                      <NavLink
                        sx={{ marginTop: 5 }}
                        data-testid="sideNavCloseAccountNavigation"
                        to="/customers/closeAccount"
                        onClick={(_menuType) => handleMenuProfile('side')}
                        className="nav_link"
                      >
                        <SideNaveList
                          SideNavicons={<DeleteAccountIcon style={{ width: '32px', height: '32px' }} />}
                          label={'Close Account'}
                        />
                      </NavLink>
                    </Box>
                  </List>
                </PerfectScrollbar>
              </Drawer>
            </div>
            <MoneySkill moneySkill={skill} onChange={setSkill} />
          </nav>
        </div>
      </ClickAwayListener>
      {/* Document Viewer TODO Likely move this and the jotai atom to select offers component and or a special layout in router so it is in one place. */}
      <DocumentViewer />
    </>
  )
  SideNaveList.propTypes = {
    label: PropTypes.string,
    disabled: PropTypes.bool,
    SideNavicons: PropTypes.string,
  }
}
