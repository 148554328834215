export let statusStrLinks = {
  approved: '/customers/finalVerification',
  completing_application: '/customers/finalVerification',
  contact_branch: '/customers/myBranch',
  confirming_info: '/partner/confirm-signup',
  expired: '/select-amount',
  invalid: '/select-amount',
  signature_complete: '/customers/loanDocument',
  offer_selected: '/customers/finalVerification',
  offers_available: '/customers/selectOffer',
  pre_qual_referred: '/select-amount',
  pre_qual_rejected: '/select-amount',
  pre_qualified: '/customers/myBranch',
  referred: '/offers/referral',
  rejected: '/offers/none-available',
  no_hit: '/customers/resumeIncomplete',
  frozen: '/customers/resumeIncomplete',
  under_review: '/customers/finalReview',
  closing_process: '/customers/finalVerification',
  final_review: '/customers/loanDocument',
  'signed_documents_&_hard_pull': '/customers/reviewAndSign',
}

export let statusStrLinks_PartnerSignUp = {
  approved: '/customers/finalVerification',
  completing_application: '/customers/finalVerification',
  contact_branch: '/customers/myBranch',
  confirming_info: '/partner/confirm-signup',
  expired: '/select-amount',
  invalid: '/select-amount',
  signature_complete: '/customers/loanDocument',
  offer_selected: '/customers/finalVerification',
  offers_available: '/customers/selectOffer',
  pre_qual_referred: '/select-amount',
  pre_qual_rejected: '/select-amount',
  pre_qualified: '/customers/myBranch',
  referred: '/offers/referral',
  rejected: '/offers/none-available',
  no_hit: '/customers/resumeIncomplete',
  frozen: '/customers/resumeIncomplete',
  under_review: '/customers/finalReview',
  closing_process: '/customers/finalVerification',
  final_review: '/customers/loanDocument',
  'signed_documents_&_hard_pull': '/customers/reviewAndSign',
  email_verification: '/partner/email-verification-affiliates',
}
