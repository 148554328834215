// stores/UTMStore.js
import { atom, useAtom } from 'jotai'
import Cookies from 'js-cookie'

const UTM_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_dealer', 'gclid', 'trkcid']

const DEFAULT_UTM = {
  utm_source_otherPartner: 'CAC',
  utm_medium_otherPartner: 'welcome',
  utm_campaign_otherPartner: '',
  sourceApplication: '',
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
  utm_dealer: '',
  gclid: '',
  trkcid: '',
  CKLightbox_Source: '',
}

export const utmAtom = atom(DEFAULT_UTM)

export const useUTMManager = () => {
  const [utm, setUtm] = useAtom(utmAtom)

  const initializeUTM = (locationState) => {
    if (typeof window === 'undefined') return // Important check for SSR

    const urlParams = new URLSearchParams(window.location.search)
    const params = {}
    UTM_PARAMS.forEach((param) => {
      const value = urlParams.get(param)
      if (value) params[param] = value
    })

    const cookieValues = {
      utm_source_otherPartner: Cookies.get('utm_source_otherPartner'),
      utm_medium_otherPartner: Cookies.get('utm_medium_otherPartner'),
      utm_campaign_otherPartner: Cookies.get('utm_campaign_otherPartner'),
      CKLightbox_Source: Cookies.get('CKLightbox_Source'),
    }

    const sourceApp = determineSourceApplication(params)

    let updatedUTM = {
      ...DEFAULT_UTM,
      ...cookieValues,
      ...params,
      sourceApplication: sourceApp,
    }

    if (locationState?.getSourceUser && locationState?.getSourceOffer) {
      updatedUTM = {
        ...updatedUTM,
        utm_source_otherPartner: cookieValues.utm_source_otherPartner || DEFAULT_UTM.utm_source_otherPartner,
        utm_medium_otherPartner: cookieValues.utm_medium_otherPartner || DEFAULT_UTM.utm_medium_otherPartner,
        utm_campaign_otherPartner:
          cookieValues.utm_campaign_otherPartner || `Mrktoffer_${locationState.getSourceOffer}`,
      }
    }

    // Persist to cookies (only specific UTM parameters)
    const cookieKeys = [
      'utm_source_otherPartner',
      'utm_medium_otherPartner',
      'utm_campaign_otherPartner',
      'CKLightbox_Source',
    ]
    cookieKeys.forEach((key) => {
      if (updatedUTM[key]) {
        Cookies.set(key, updatedUTM[key])
      }
    })

    if (sourceApp === 'CKLightbox') {
      Cookies.set('CKLightbox_Source', 'CKLightbox')
    }

    setUtm(updatedUTM)
  }

  const updateUTM = (updates) => {
    setUtm((prev) => {
      const newUTM = { ...prev, ...updates }

      // Update relevant cookies
      const cookieKeys = [
        'utm_source_otherPartner',
        'utm_medium_otherPartner',
        'utm_campaign_otherPartner',
        'CKLightbox_Source',
      ]
      cookieKeys.forEach((key) => {
        if (newUTM[key]) {
          Cookies.set(key, newUTM[key])
        }
      })

      return newUTM
    })
  }

  const resetUTM = () => {
    setUtm(DEFAULT_UTM)
    const cookieKeys = [
      'utm_source_otherPartner',
      'utm_medium_otherPartner',
      'utm_campaign_otherPartner',
      'CKLightbox_Source',
    ]
    cookieKeys.forEach((key) => Cookies.remove(key))
  }

  const determineSourceApplication = (params) => {
    if (params.trkcid) return 'CKLightbox'
    if (params.utm_source === 'CredibleR') return 'CredibleR'
    if (params.utm_source === 'Adwords') return 'Adwords'
    if (params.utm_source === 'Googleads') return 'Googleads'
    return ''
  }

  return {
    utm,
    setUtm, // Expose setUtm if needed for direct manipulation
    initializeUTM,
    updateUTM,
    resetUTM,
  }
}
