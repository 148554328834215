/*
#################################################################################################################

File Name           :    Select/index.js
Component Name      :    Single Select
Functionality       :    To use this Select Box as a default component for UI purpose across the whole application to
													maintain same consistency.

#################################################################################################################
 */

import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import PropTypes from 'prop-types'
import React from 'react'
import './SelectBox.css'

const SelectWrapper = ({
  name,
  variant,
  select,
  labelform,
  selectTestID,
  inputTestID,
  value,
  onChange,
  helperText,
  refId,
  ...otherProps
}) => {
  //Configuring Field with Properties
  const configSelect = {
    ...otherProps,
    fullWidth: true,
    variant: variant,
  }
  const configFormControl = {
    fullWidth: true,
  }

  function isValidJSON(input) {
    try {
      JSON.parse(input)
      return true
    } catch {
      return false
    }
  }

  //Validation Part
  let selectMF = isValidJSON(select) ? JSON.parse(select) : []

  //View Part
  return (
    <FormControl {...configFormControl}>
      <InputLabel>{labelform}</InputLabel>
      <Select
        {...configSelect}
        name={name}
        variant="standard"
        value={value}
        onChange={onChange}
        inputRef={refId}
        data-testid={selectTestID ?? 'selectBox'}
        inputProps={{ 'data-testid': inputTestID ?? 'selectInput' }}
      >
        {selectMF &&
          selectMF.map((nam, idx) => (
            <MenuItem key={`${nam.value}-${idx}`} value={nam.value}>
              <span className="subOption" value={nam.value}>
                {nam.label ? nam.label : nam.value}
              </span>
            </MenuItem>
          ))}
      </Select>
      <FormHelperText error={true}>{helperText}</FormHelperText>
    </FormControl>
  )
}

SelectWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
  required: PropTypes.bool,
  select: PropTypes.string,
  labelform: PropTypes.string,
  selectTestID: PropTypes.string,
  inputTestID: PropTypes.string,
  refId: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  helperText: PropTypes.string,
}

export default SelectWrapper
