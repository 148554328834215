import React, { createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types'
// Define the context
const SessionContext = createContext();

// Initial state
const initialState = {
  currentStep: 1, // initial step
  sessionID: undefined,
  mfaOptions: {
    phone_numbers: [],
    securityQuestions: [],
    securityQuestionsSaved: false,
  },
  isVerified: false,
  completedSteps: [], // to be used as enum to set current step
  selectedPhoneNumber: null ,
  isLocked : false,
};

// Reducer function to update state
const sessionReducer = (state, action) => {
  switch (action.type) {
    case 'SET_IS_VERIFIED':
      return {
        ...state,
        isVerified: action.payload,
      };
    case 'SET_SESSION':
      // merge session data with current state
      return {
        ...state,
        session: {
          ...state.session,
          ...action.payload,
        },
      };
    case 'SET_COMPLETED_STEPS':
      return {
        ...state,
        completedSteps: action.payload,
      };
    // OPTIONAL IF WE DON'T RETURN COMPLETED STEPS FROM API
    case 'SET_CURRENT_STEP':
      return {
        ...state,
        currentStep: action.payload,
      };
    case 'SET_MFA_OPTIONS':
      return {
        ...state,
        mfaOptions: action.payload,
      };
    case 'SELECTED_PHONE_NUMBER':
      return {
        ...state,
        selectedPhoneNumber: action.payload,
      };  
    case 'SET_IS_LOCKED':
      return {
        ...state,
        isLocked: true,
      };  
    case 'RESET':
      return initialState
    // ... other cases for updating state
    default:
      if (process.env.NODE_ENV === 'development') {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
      // OPTIONAL WE CAN FORCE THE REDIRECT TO FORGOT PASSWORD PAGE HERE IT MEANS NO SESSION OR INVALID RESPONSES/SCENARIOS FROM API
      return state;
  }
};

// Create a provider component
export const SessionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(sessionReducer, initialState);

  return (
    <SessionContext.Provider value={{ state, dispatch }}>
      {children}
    </SessionContext.Provider>
  );
};

// Hook for easy context usage
export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
};

SessionProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
}


