import { makeStyles } from '@mui/styles'
import overlayImage from '../images/mariner_overlay_Logo.png'

//Styling
const preLoginStyle = makeStyles((Theme) => ({
  mainDiv: {
    backgroundColor: '#f6f6f6',
    minHeight: '500px !important',
  },
  smallText: {
    color: '#4a4e57',
    fontSize: '.8rem',
  },
  blueBackground: {
    backgroundColor: '#013474',
  },
  secondTopBar: {
    backgroundColor: '#013474',
    padding: '20px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  callUsText: {
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexWrap: 'wrap',
    fontSize: '1.5rem',
    '@media (max-width: 480px)': {
      fontSize: '1.125rem',
    },
  },

  callUsNumber: {
    fontWeight: 'bolder',
    color: '#febd10',
    cursor: 'pointer',
    textDecoration: 'none',
    [Theme.breakpoints.down('sm')]: {
      marginLeft: '0',
      marginTop: '8px',
      width: '100%',
    },
  },
  whiteBox: {
    backgroundColor: '#fff',
    margin: '15px auto',
    borderRadius: '10px',
    paddingBottom: '5%',
    position: 'relative',
    backgroundImage: `url(${overlayImage})`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
  },
  yellowBackground: {
    backgroundColor: '#febd10',
    paddingBottom: '6%',
    borderRadius: '0 0 10px 10px',
  },
  yellowAccent: {
    backgroundColor: '#febd10',
    padding: '8%',
    borderRadius: '0 0 10px 10px',
  },
  componentWrap: {
    backgroundColor: '#f6f6f6',
    boxSizing: 'border-box',
    width: '100%',
  },

  blueBoxWrap: {
    padding: '0% 5% 15% 5%',
    borderRadius: '10px',
    boxSizing: 'border-box',
    maxWidth: '100%',
    background: '#013474',
  },

  callNowWrap: {
    padding: '2% 0%',
  },
  gloBlueBoxWrap: {
    padding: '5% 5% 0 5%',
  },
  whiteRectangle: {
    backgroundColor: '#fff',
    display: 'flex',
    margin: '-80px 5%',
    borderRadius: '10px',
    zIndex: '1',
    padding: '2%',
    justifyContent: 'center',
    [Theme.breakpoints.down('sm')]: {
      margin: '-40px 5%',
      width: '100%',
    },
  },
  branchHoursText: {
    padding: '40px 0px',
    fontWeight: '600',
    fontSize: '1.438 rem',
  },

  callNowIcon: {
    maxWidth: '92px',
    display: 'flex',
    margin: '0 auto',
    marginBottom: '20px',
  },
  carInsuranceIcon: {
    maxWidth: '92px',
    width: '100%',
  },
  subHeading: {
    color: '#fff',
    textAlign: 'center',
    padding: '4%',
  },

  altHeading: {
    color: '#fff',
    textAlign: 'center',
    padding: '4% 4% 0 4%',
  },
  securedLoansGrid: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
  },
  securedLoansText: {
    color: '#000',
    textAlign: 'center',
    padding: '7%',
  },
  keyPointsGrid: {
    paddingBottom: '30px',
    display: 'flex',
    alignItems: 'flex-start',
  },
  checkedPointImage: {
    maxWidth: '26px',
    marginRight: '10px',
  },
  addressText: {
    padding: '4% 0 3% 0',
  },
  phoneNumberLink: {
    textDecoration: 'none',
  },
  carInsuranceIconGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  overlayImage: {
    position: 'absolute',
    bottom: '0',
    right: '0',
  },
  goldIcon: {
    width: '97px',
    height: '97px',
  },
  paddingGrid: {
    padding: '4% 0px',
  },
  paperStyle: {
    width: 'inherit',
    textAlign: 'center',
  },
  marginTop: {
    marginTop: '-3%',
  },
  boxGrid: {
    padding: '4% 0px 4% 0px',
  },
  checkMyOffersPaperStyle: {
    justify: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  typoStyle: {
    align: 'center',
    justify: 'center',
    alignItems: 'center',
    fontSize: '1.538rem',
    margin: '10px 0px !important',
    color: '#171717',
    fontWeight: '400 !important',
    lineHeight: '110% !important',
  },
  linkDesign: {
    textDecoration: 'underline !important',
    color: '#0F4EB3 !important',
    display: 'block !important',
    cursor: 'pointer',
  },
  linkDesignNoBlock: {
    textDecoration: 'underline !important',
    color: '#0F4EB3 !important',
    cursor: 'pointer',
  },
  citizenPaperStyle: {
    justify: 'center',
    alignItems: 'center',
    width: 'inherit',
    textAlign: 'center',
  },
  gridStyle: {
    padding: '4% 0px 4% 0px',
  },
  EmploymentStatusBoxGrid: {
    padding: '4% 0px 4% 0px',
    marginTop: '5%',
    marginBottom: '2%;',
  },
  exsistingUserTypoStyle: {
    align: 'center',
    justify: 'center',
    alignItems: 'center',
    marginBottom: '1%',
    marginTop: '1%',
  },
  negativeMargin: {
    marginTop: '-4%',
  },
  exsistingUserPaperStyle: {
    justify: 'center',
    alignItems: 'center',
    width: 'inherit',
    marginBottom: '10%',
    marginTop: '10%',
    textAlign: 'center',
  },
  homwAdressGridStyle: {
    padding: '4% 0',
    margin: 'auto',
    // marginTop: '5%'
    marginBottom: '2%;',
  },
  homwAdressPaperStyle: {
    justify: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  subPaper: {
    margin: '0 15px 15px',
    padding: '10px 15px',
    boxShadow: '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%)',
    position: 'relative',
    borderRadius: '4px !important',
    transition: 'box-shadow .25s',
    backgroundColor: '#fff',
    textAlign: 'justify',
  },
  paraInsideSubPaper: {
    fontFamily: "'Muli', sans-serif",
    color: '#595959',
    fontSize: '15px',
  },
  livingSpaceBoxGrid: {
    padding: '4% 0px 4% 0px',
    marginTop: '5%',
    marginBottom: '2%;',
  },
  paper: {
    padding: Theme.spacing(2),
    height: '100%',
    textAlign: 'center',
    color: Theme.palette.text.secondary,
    boxSizing: 'border-box',
  },
  gridPadding: {
    paddingTop: '7px',
    paddingBottom: '15px',
  },
  gridItem: {
    boxSizing: 'border-box',
    padding: Theme.spacing(1),
  },
  masonryItemFirst: {
    padding: Theme.spacing(1),
    boxSizing: 'border-box',
  },
  mainGridPadding: {
    padding: '4% 0%',
  },
  gridMargin: {
    margin: '15px 0px 19px 0 !important',
  },
  maritalStatustypoStyle: {
    align: 'center',
    justify: 'center',
    alignItems: 'center',
    marginBottom: '1%',
    marginTop: '1%',
  },
  maritalStatusPaperStyle: {
    justify: 'center',
    alignItems: 'center',
    width: 'inherit',
    marginBottom: '10%',
    marginTop: '10%',
    textAlign: 'center',
  },
  newUserBoxGrid: {
    width: '100%',
    paddingTop: '70px',
    paddingBottom: '70px',
  },
  newUserTypoStyle: {
    fontSize: '0.938rem',
    color: '595959',
  },
  oneLastStepLinks: {
    marginTop: '3px !important',
    marginBottom: '3px !important',
    marginLeft: '7%',
    paddingLeft: '5px',
    textDecoration: 'underline !important',
    color: '#0F4EB3 !important',
    display: 'block !important',
    cursor: 'pointer',
    textAlign: 'left',
  },
  oneLastStepLinksWrap: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  paddingOneSide: {
    padding: '4% 0px',
  },
  fullWidth: {
    width: '100%',
  },
  typoAlign: {
    textAlign: 'left',
    marginLeft: '8%',
    marginTop: '2%',
  },
  personalInfoPaperStyle: {
    justify: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingBottom: '40px',
  },
  justifyGrid: {
    justifyContent: 'center',
    alignItems: 'stretch',
    textAlign: 'center',
    padding: '0% 4%',
  },
  justifyGridMargin: {
    justifyContent: 'center',
    margin: ' 15px 0px 19px 0px',
  },
  gridAlign: {
    justifyContent: 'center',
    padding: '4% 0%',
  },
  offerAmountStyle: {
    color: '#0F4EB3',
    lineHeight: '110%',
    fontFamily: "'Muli', sans-serif",
    fontWeight: '400',
    fontSize: '1.64rem',
    marginTop: '5px',
    marginBottom: '5px',
  },
  preApprovedTypoStyle: {
    color: 'black',
    fontWeight: '400',
    fontFamily: 'Muli, sans-serif',
  },
  smallTextStyle: {
    paddingTop: '25px',
    paddingBottom: '70px',
    marginBottom: '3%',
  },
  cardWrapper: {
    paddingTop: '4%',
    marginTop: '5%',
    marginBottom: '2%',
  },
  showSection: {
    color: '#fec33a',
  },
  hideSection: {
    color: '#c4c4c4',
  },
  submitApplicationRecaptcha: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '10px',
  },
  dialogActionStyle: {
    justifyContent: 'center',
    paddingBottom: '4%',
  },
  dialogHeading: {
    fontSize: '1.563rem',
    textAlign: 'center',
    padding: '0% 2%',
    '@media (max-width: 700px)': { fontSize: '1rem' },
  },
  guaranteeMsgContainer: {
    marginTop: '30px',
    paddingLeft: '40px',
    paddingRight: '40px',
    paddingBottom: '20px',
  },
  completeLoanText: {
    padding: '20px 40px', // 40px left and right padding
  },
  offerValidStyle: {
    paddingTop: '20px',
  },
  offerInformationText: {
    fontSize: '14px',
    paddingTop: '40px',
    fontWeight: 'bold',
    paddingBottom: '40px',
  },
  offerInformationSpan: {
    fontStyle: 'italic',
    paddingLeft: '20px',
    color: '#013474',
  },
  optOutNoticeContainer: {
    border: '2px solid #013474',
    padding: '20px',
    borderRadius: '10px',
  },
  optOutNoticeText: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  optOutNoticeSpan: {
    textDecoration: 'underline',
  },
  optOutNoticeComponentContainer: {
    border: '2px solid #013474',
    borderRadius: '10px',
    padding: '20px 10px',
  },
  optOutNoticeComponentOptNotice: {
    textDecoration: 'underline',
    paddingBottom: '20px',
    textAlign: 'center',
    fontWeight: '700',
  },
  optOutNoticeComponentText: {
    fontWeight: '600',
  },
  offerInformationText: {
    fontSize: '16px',
  },
  offerInformationContainer: {
    paddingTop: '40px',
  },

  branchHoursAndKeyPointsWrap: {
    display: 'flex',
    justifyContent: 'center',
  },
  branchHoursWrap: {
    display: 'flex',
    justifyContent: 'center',
  },
  keyPointsWrap: {
    display: 'flex',
  },
}))

export { preLoginStyle }
