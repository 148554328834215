'use strict'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { useUTMManager } from '../../stores/UTMStore'

/**
 * CaptureUTMInfo component initializes UTM information using the UTM manager.
 *
 * This component uses the `useUTMManager` hook to get the `initializeUTM` function
 * and the `useLocation` hook to get the current location. It then uses a `useEffect`
 * hook to call `initializeUTM` with the location state whenever the location or
 * `initializeUTM` changes.
 *
 * @component
 * @returns {null} This component does not render any UI.
 */
export default function CaptureUTMInfo() {
  const { initializeUTM } = useUTMManager()
  const location = useLocation()

  React.useEffect(() => {
    initializeUTM(location.state)
  }, [location, initializeUTM])

  return null
}
