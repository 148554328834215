import Buffer from 'buffer'
import printJS from 'print-js'
import { toast } from 'react-toastify'
import globalMessages from '../../assets/data/globalMessages.json'
import APICall from '../lib/AxiosLib'
import ErrorLogger from '../lib/ErrorLogger'
import Cookies from 'js-cookie'

/***** Get loan document *****/
export async function loanDocumentController(accountNumber) {
  try {
    let url = !accountNumber ? 'active_loan_document' : 'loan_document'
    let param = url === 'loan_document' ? '/' + accountNumber : ''
    let data = {}
    let method = 'GET'
    let addAccessToken = true

    //API call
    return await APICall(url, param, data, method, addAccessToken)
  } catch (error) {
    ErrorLogger(globalMessages.Error_executing_loanDocumentController_API, error)
  }
}

/*****getCreditScoreDisclosureFile *****/
export async function getCreditScoreDisclosureFile() {
  try {
    let url = 'get_credit_score_disclosure'
    let param = ''
    let data = Object.assign(localStorage?.getItem('user') ? { user: localStorage?.getItem('user') } : {})
    let method = 'POST'
    let addAccessToken = true

    //API call
    return await APICall(url, param, data, method, addAccessToken)
  } catch (error) {
    ErrorLogger(globalMessages.Error_executing_loanDocumentController_API, error)
  }
}

/***** Download and converting bufferdata *****/
function downloadFileData(fileData) {
  Buffer = require('buffer/').Buffer // note: the trailing slash is important!
  const buff = Buffer.from(fileData?.data?.bufferFile.data)
  const url = window.URL.createObjectURL(new Blob([buff]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileData?.data?.exportName)
  document.body.appendChild(link)
  link.click()
  if (!toast.isActive('closeToast')) {
    toast.success(globalMessages.Document_download, { toastId: 'closeToast' })
  }
}

/****** Document Download method *****/
export async function documentdownload(id, name, fileURL) {
  try {
    let url = 'cac_download_document'
    let param = ''
    let data = {
      file_id: id,
      name: name,
      fileURL: fileURL,
    }
    let method = 'POST'
    let addAccessToken = true

    //API call
    let loanDocumentDownload = await APICall(url, param, data, method, addAccessToken)
    loanDocumentDownload.status === 200
      ? downloadFileData(loanDocumentDownload)
      : toast.error(loanDocumentDownload?.data?.message ?? globalMessages.Document_download_error)
  } catch (error) {
    ErrorLogger(globalMessages.Error_executing_loanDocumentController_API, error)
  }
}

/***** Print file *****/
function print(data) {
  Buffer = require('buffer/').Buffer // note: the trailing slash is important!
  const buff = Buffer.from(data?.data?.bufferFile.data)
  let pdfFile = new Blob([buff])
  let pdfUrl = URL.createObjectURL(pdfFile)
  printJS(pdfUrl)
}

/***** upload document method *****/
export async function uploadDocument(fileData, fileName, fileType, documentType) {
  try {
    let url = 'upload_document'
    let param = ''
    let data = {
      compressedFile: [
        {
          data: fileData,
          mimetype: fileType,
          documentType: documentType,
          fileName: fileName,
        },
      ],
    }
    let method = 'POST'
    let addAccessToken = true

    //API call
    let uploadData = await APICall(url, param, data, method, addAccessToken)
    //API response
    uploadData.status === 200
      ? toast.success(uploadData?.data?.message ?? globalMessages.Document_upload)
      : toast.error(uploadData?.data?.message ?? globalMessages.Document_upload_error)

    return true
  } catch (error) {
    ErrorLogger(globalMessages.Error_executing_loanDocumentController_API, error)
  }
}

/**
 * Fetches and processes credit score disclosure document
 * @returns {Promise<string>} Blob URL for the document
 * @throws {Error} If the API call fails or response is invalid
 */
export async function getCreditScoreDisclosure() {
  try {
    // Get required IDs from cookies
    const userCustomerId = Cookies.get('userCustomerId')
    const userApplicantId = Cookies.get('userApplicantId')

    // Prepare request data
    const requestData = {
      user: Cookies.get('userId'),
      customer_id: userCustomerId,
      applicant_id: userApplicantId,
    }

    // Make API call
    const response = await APICall('get_credit_score_disclosure', '', requestData, 'POST', true)

    // Validate response
    if (!response?.status === 200 || !response?.data) {
      throw new Error('Invalid response from server')
    }

    const { content, fileType, fileName } = response.data

    // Validate required data
    if (!content || !fileType) {
      throw new Error('Missing required file data from server')
    }

    // Convert base64 to blob
    const binaryContent = atob(content)
    const bytes = new Uint8Array(binaryContent.length)

    for (let i = 0; i < binaryContent.length; i++) {
      bytes[i] = binaryContent.charCodeAt(i)
    }

    // Create blob and URL
    const blob = new Blob([bytes], {
      type: fileType,
    })

    // Create and return blob URL
    const blobUrl = URL.createObjectURL(blob)

    // Add cleanup function to window object
    window.creditScoreDisclosureCleanup = () => {
      URL.revokeObjectURL(blobUrl)
      delete window.creditScoreDisclosureCleanup
    }

    return blobUrl
  } catch (error) {
    ErrorLogger(globalMessages.Error_executing_GetCreditScoreDisclosure_API, error)
    throw new Error(error.message || 'Failed to fetch credit score disclosure')
  }
}

/**
 * Cleanup function to revoke blob URLs
 * Should be called when component unmounts or document viewer closes
 */
export function cleanupCreditScoreDisclosure() {
  if (window.creditScoreDisclosureCleanup) {
    window.creditScoreDisclosureCleanup()
  }
}
