/*#################################################################################################################

File Name           :    TextField/index.js
Component Name      :    TextField
Functionality       :    To use this component to validate and get the input from the user as text field.

#################################################################################################################*/
import { Box, FormLabel, Grid, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import globalMessages from '../../../assets/data/globalMessages.json'
import dynamicStyles from '../OutlineStyles'

const TextFieldWrapper = ({
  name,
  design,
  label,
  required,
  color,
  lableSize,
  margin,
  padding,
  type,
  materialProps,
  setError,
  setHelperText,
  error,
  helperText,
  onChange,
  InputProps,
  ...otherProps
}) => {
  const [errorTF, setErrorTF] = useState(false)
  const [helperTextTF, setHelperTextTF] = useState('')

  const useStyles = makeStyles((theme) => ({
    textFieldWrapper: {
      width: 'inherit',
      padding: '0 10px',
    },
    cssLabel: {
      backgroundColor: color,
      fontSize: lableSize,
      padding: padding,
    },
    formLabelStyle: {
      paddingLeft: '2px',
      paddingBottom: '5px',
    },
    '&$notchedOutline': {
      borderColor: 'green !important',
    },
  }))

  const classes = useStyles()
  const borderClasses = dynamicStyles()

  const configTextField = {
    name: name,
    required: required,
    fullWidth: true,
    error: error ?? errorTF,
    helperText: helperText || setHelperText || helperTextTF,
    classes: {
      root: borderClasses.dynamic,
    },
    InputLabelProps: {
      classes: {
        root: classes.cssLabel,
        focused: classes.cssFocused,
      },
    },
    ...otherProps,
  }

  const handleOnchange = (event) => {
    setErrorTF(required && !event.target.value)
    setHelperTextTF(required && !event.target.value ? globalMessages.required : '')
    if (onChange) {
      onChange(event)
    }
  }

  return (
    <Grid className={classes.textFieldWrapper}>
      {design === 'new' ? (
        <Grid>
          <FormLabel className={classes.formLabelStyle}>{label}</FormLabel>
        </Grid>
      ) : null}
      <TextField
        {...configTextField}
        variant={design === 'new' ? 'outlined' : 'standard'}
        label={design === 'new' ? '' : label}
        onChange={handleOnchange}
        InputProps={InputProps}
        inputProps={materialProps}
      />
    </Grid>
  )
}

TextFieldWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  design: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  color: PropTypes.string,
  lableSize: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  type: PropTypes.string,
  materialProps: PropTypes.object,
  setError: PropTypes.bool,
  setHelperText: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  InputProps: PropTypes.object,
  disabled: PropTypes.bool,
}

export default TextFieldWrapper
