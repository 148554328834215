import { useSetAtom } from 'jotai'
import { AccountDetailsAtom } from '../AccountDetailsStore'
import { IsLoadingAtom } from '../IsLoadingAtom'
import usrAccountDetails from '../../components/Controllers/AccountOverviewController'
import ErrorLogger from '../../components/lib/ErrorLogger'
import globalMessages from '../../assets/data/globalMessages.json'

export const useFetchAccountDetails = () => {
  const setAccountDetailsAtom = useSetAtom(AccountDetailsAtom)
  const setIsLoading = useSetAtom(IsLoadingAtom)

  const fetchAccountDetails = async () => {
    setIsLoading(true)
    try {
      const accountDetails = await usrAccountDetails()
      setAccountDetailsAtom(accountDetails)
    } catch (error) {
      ErrorLogger(globalMessages.Error_executing_AccountDetails_API, error)
    } finally {
      setIsLoading(false)
    }
  }

  return fetchAccountDetails
}
