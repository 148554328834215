import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { ButtonPrimary } from '../../FormsUI'
import './Popup.css'
import globalMessages from '../../../assets/data/globalMessages.json'

const usePopUp = makeStyles((theme) => ({
  paragraph: {
    fontSize: '1.56rem',
    fontWeight: 'bolder',
    textAlign: 'left !important',
    '@media (max-width: 480px)': {
      fontSize: '1.4rem',
      textAlign: 'left !important',
    },
  },
  closeIconGrid: {
    position: 'absolute',
    top: '20px',
    right: '20px',
  },
  closeIconStyle: {
    color: ' #585858',
    float: 'right',
    cursor: 'pointer',
  },
  noticeText: {
    color: '#1a365d',
    fontWeight: '600',
    fontSize: '0.875rem',
    marginRight: '1rem',
  },
  noticeLink: {
    color: '#2563eb',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  listTitle: {
    marginTop: '1.56rem !important',
  },
  noticeTextTosCard: {
    color: 'blue !important',
    textDecoration: 'underline !important',
    fontWeight: '600 !important',
    cursor: 'pointer', // Added line
  },
  noticeTextReview: {
    fontWeight: '600 !important',
  },
}))

const Popup = ({
  children,
  popupFlag,
  title,
  closePopup,
  maxWidth,
  buttonText,
  buttonStyle,
  button2Text,
  button2Display,
  button2Style,
  removeContentBorder,
  handlebuttonClick,
  handlebutton2Click,
  hideOkButton,
  showCreditNotice,
  onCreditNoticeClick,
}) => {
  const classes = usePopUp()
  const btn2Display = button2Display ? button2Display : 'none'

  return (
    <Dialog
      onClose={closePopup}
      aria-labelledby="customized-dialog-title"
      open={popupFlag}
      data-testid="popup"
      id="customeDialogBox"
      maxWidth={maxWidth}
    >
      <DialogTitle className="dialogTitleWrap" id="customized-dialog-title" onClose={closePopup}>
        <Grid container>
          {title ? (
            <Grid item sm={10}>
              <Typography className={classes.paragraph}>{title ?? ''}</Typography>
            </Grid>
          ) : (
            <></>
          )}

          <Grid className={classes.closeIconGrid}>
            <CloseIcon data-testid="closeIcon" className={classes.closeIconStyle} onClick={closePopup} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent data-testid="content" dividers={!removeContentBorder}>
        {children}
      </DialogContent>
      <DialogActions className="modalAction mfaModelAction">
        <Grid
          container
          alignItems="center"
          justifyContent={showCreditNotice ? 'space-around' : 'center'}
          justifyItems="center"
          wrap="nowrap"
        >
          {showCreditNotice && (
            <Grid item>
              <Typography className={classes.noticeTextReview}>
                <span underline="hover" onClick={onCreditNoticeClick} className={classes.noticeTextTosCard} href="#">
                  {globalMessages.Credit_Score_Disclosure_Title}
                </span>{' '}
                {globalMessages.Global_Notice_Review}.
              </Typography>
            </Grid>
          )}
          <Grid item>
            <ButtonPrimary
              data-testid="modalButton2"
              stylebutton={
                button2Style ??
                `{"background": "#FFBC23", "color": "black", "borderRadius": "50px", "display": \"${btn2Display}\"}`
              }
              onClick={handlebutton2Click ?? closePopup}
              className="MFAModalButton"
            >
              <Typography align="center" className="buttonText">
                {button2Text ?? 'Cancel'}
              </Typography>
            </ButtonPrimary>
          </Grid>
          <Grid item>
            <ButtonPrimary
              data-testid="modalButton"
              stylebutton={JSON.stringify({
                background: '#FFBC23',
                color: 'black',
                borderRadius: '50px',
              })}
              onClick={handlebuttonClick ?? closePopup}
              className={hideOkButton ? 'hideMsg' : 'MFAModalButton'}
            >
              <Typography align="center" className="buttonText">
                {buttonText ?? 'Ok'}
              </Typography>
            </ButtonPrimary>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

Popup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  popupFlag: PropTypes.bool,
  openPopup: PropTypes.func,
  handlebuttonClick: PropTypes.func,
  handlebutton2Click: PropTypes.func,
  closePopup: PropTypes.func,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
  buttonText: PropTypes.string,
  buttonStyle: PropTypes.string,
  button2Text: PropTypes.string,
  button2Display: PropTypes.string,
  button2Style: PropTypes.string,
  removeContentBorder: PropTypes.bool,
  hideOkButton: PropTypes.bool,
  showCreditNotice: PropTypes.bool,
  onCreditNoticeClick: PropTypes.func,
}

export default Popup
