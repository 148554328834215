export const CheckOffers = {
  loanAmount: '',
  term: 36,
  offerCode: '',
  zip: '',
  loanPurpose: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  dob: '',
  employmentStatus: '',
  yearsAtEmployers: '',
  EmployerPhone: '',
  householdAnnualIncome: '',
  annualIncome: '',
  maritalStatus: '',
  spouse_address_street: '',
  spouse_address_city: '',
  spouse_address_postal_code: '',
  spouse_address_state: '',
  spouse_address_state_full_form: '',
  streetAddress: '',
  city: '',
  state: '',
  stateFullform: '',
  ssn: '',
  homeOwnership: '',
  rentMortgageAmount: '',
  militaryActiveDuty: '',
  consent_credit_contact_auth: '',
  consent_electronic_communication: '',
  consent_privacy_policy: '',
  consent_terms_of_use: '',
  militaryActiveDutyRank: '',
  nontaxable_annual_income: '',
  VADisclosure: '',
  password: '',
  confirmPassword: '',
  result: '',
  formStatus: '',
  completedPage: 0,
  loading: true,
  isActiveUser: '',
  disabled: false,
  last4SSN: '',
  hoverColor: true,
  amount: true,
  page: {
    selectAmount: 1,
    getStarted: 2,
    loanPurpose: 3,
    homeAddress: 4,
    livingPlace: 5,
    personalInfo: 6,
    employmentStatus: 7,
    annualIncome: 8,
    householdIncome: 9,
    marriedStatus: 10,
    VADisclosure: 10,
    newUser: 11,
    emailVerificationPasscode: 12,
    noHit: 13,
    frozen: 13,
  },
  borrowerType: '',
  applicationStatus: '',
  trkcid: '',
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
  utm_source_otherPartner: '',
  utm_medium_otherPartner: '',
  utm_campaign_otherPartner: '',
  referer_otherPartner: '',
  gclid_otherPartner: '',
  termsOfService: false,
  contactAuthorization: false,
  livingPlaceDisclosure: false,
  hasSpecialIncome: '',
}
