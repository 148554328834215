/**
 * @fileoverview React component for retrieving and displaying documents using an API.
 * This component displays a document in a modal dialog.
 * The component uses Jotai for state management and includes error handling and loading states.
 * It also provides functionality to print and download the document.
 *
 * @component
 * @returns {JSX.Element} The DocumentViewer component.
 * Example usage:
 * <DocumentViewer />
 **/
import React, { useEffect } from 'react'
import { useAtom } from 'jotai'
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Skeleton } from '@mui/material'
import { ButtonPrimary, ButtonSecondary } from '../../FormsUI'
import { getCreditScoreDisclosure, cleanupCreditScoreDisclosure } from '../../Controllers/LoanDocumentController'
import { useQuery } from 'react-query'
import globalMessages from '../../../assets/data/globalMessages.json'
import './DocumentViewer.css'
import { isDocumentViewerOpenAtom, documentViewerParamsAtom, documentUrlAtom } from '../../../stores/FilesStore'

import { toast } from 'react-toastify'

/**
 * DocumentViewer Component
 * Displays a document in a modal dialog with print and download capabilities.
 * Handles document fetching, loading states, and cleanup.
 */
function DocumentViewer() {
  const [isOpen, setIsOpen] = useAtom(isDocumentViewerOpenAtom)
  const [documentViewerParams] = useAtom(documentViewerParamsAtom)
  const [docUrl, setDocUrl] = useAtom(documentUrlAtom)
  // Fetch document using React Query
  const {
    data: documentUrl,
    isLoading,
    error,
    refetch,
  } = useQuery(['creditScoreDisclosure', documentViewerParams.applicantId], () => getCreditScoreDisclosure(), {
    enabled: isOpen, // Only fetch when dialog is open
    retry: 1, // Only retry once on failure
    staleTime: 0, // Always fetch fresh data
    cacheTime: 0, // Don't cache the blob URLs
    onError: (error) => {
      toast.error('Failed to fetch credit score disclosure. Please try again later.')
    },
  })

  useEffect(() => {
    if (documentUrl) {
      setDocUrl(documentUrl)
    } else {
      setDocUrl(null)
    }
  }, [documentUrl])

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      setDocUrl(null)
      cleanupCreditScoreDisclosure()
    }
  }, [])

  // Handle dialog close
  const handleClose = () => {
    setIsOpen(false)
    setDocUrl(null)
    cleanupCreditScoreDisclosure()
  }

  // Handle document printing
  const handlePrint = () => {
    if (!documentUrl) return

    try {
      const printWindow = window.open(docUrl, '_blank', 'noopener,noreferrer')
      if (printWindow) {
        printWindow.addEventListener('load', () => {
          printWindow.focus()
          printWindow.print()
        })
      } else {
        throw new Error('Popup blocked. Please allow popups to print.')
      }
    } catch (error) {
      const iframe = document.querySelector('#document-viewer-iframe')
      if (iframe) {
        iframe.contentWindow.print()
      }
    }
  }

  // Handle document download
  const handleDownload = () => {
    if (!docUrl) return

    try {
      const link = document.createElement('a')
      link.href = docUrl
      link.download = 'credit_score_disclosure.pdf'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      toast.error('Failed to download document. Please try again later.')
    }
  }

  // Handle reload attempt
  const handleRetry = () => {
    cleanupCreditScoreDisclosure()
    refetch()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      aria-labelledby="document-viewer-title"
      className="document-viewer-dialog"
    >
      <DialogTitle id="document-viewer-title">
        <span>{documentViewerParams.documentName}</span>
        <IconButton aria-label="Close" onClick={handleClose} className="absolute right-2 top-2">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers className="relative min-h-[600px]" id="doc-viewer-container">
        {error && (
          <div id="document-viewer-error-message">
            <p className="text-red-500 mb-4">
              {globalMessages.Failed_Loading_Credit_Disclosure ||
                'Failed loading credit score disclosure. Please try again. If you continue experiencing issues, please contact support.'}
            </p>
            <ButtonSecondary onClick={handleRetry} stylebutton='{"background": "", "color":"" }'>
              Try Again
            </ButtonSecondary>
          </div>
        )}

        {docUrl && !isLoading && (
          <iframe
            id="document-viewer-iframe"
            src={docUrl}
            className="w-full h-full border-0"
            title="Document Viewer"
            aria-label="Document Viewer"
            allowFullScreen
          />
        )}

        {!docUrl && isLoading && !error && (
          <Skeleton variant="rectangular" width="100%" height={600} animation="wave" className="bg-gray-100" />
        )}
      </DialogContent>

      <DialogActions className="p-4 flex justify-end gap-4">
        <ButtonSecondary
          stylebutton='{"background": "", "color":"" }'
          onClick={handlePrint}
          disabled={!documentUrl || isLoading}
        >
          {globalMessages.DocumentViewer_Action_Print || 'Print'}
        </ButtonSecondary>

        <ButtonPrimary
          stylebutton='{"background": "", "color":"" }'
          onClick={handleDownload}
          disabled={!documentUrl || isLoading}
        >
          {globalMessages.DocumentViewer_Action_Download || 'Download'}
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  )
}

export default DocumentViewer
